var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"information"},[_c('div',{staticClass:"information-haeder-box"},[_c('div',{staticClass:"information-haeder"},[_c('div',[_c('div',{staticClass:"quan",on:{"click":_vm.back}},[_c('img',{attrs:{"src":require("../../../assets/img/views/jiantou.png"),"alt":""}})]),_c('span',{staticClass:"xiangq"},[_vm._v(_vm._s(_vm.$route.meta.title))])]),_c('div',[_c('el-button',{staticClass:"haeder-fuzhi",attrs:{"type":"primary","size":"small"},on:{"click":function($event){return _vm.copy(_vm.textData)}}},[_vm._v("一键复制")])],1)])]),_c('el-breadcrumb',{staticClass:"breadcrumb",attrs:{"separator-class":"  "}},[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/systemSettings/index' }}},[_vm._v("系统设置")]),_c('el-breadcrumb-item',[_c('span',{staticClass:"breadcrumb-meaasge"},[_vm._v("/")]),_vm._v(" 信息详情 ")])],1),_c('div',{staticClass:"information-box"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"informaton-main"},[_c('div',{staticClass:"main-top"},[_c('div',{staticClass:"main-top-left"}),_c('div',{staticClass:"main-top-center"},[_c('p',{staticClass:"center-title",domProps:{"innerHTML":_vm._s(_vm.textData.title ? _vm.textData.title : '')}}),_c('div',{staticClass:"caozuo-center"},[_c('div',{class:[
                'type',
                _vm.textData.tonalState == 2
                  ? 'red'
                  : _vm.textData.tonalState == 1
                  ? 'neutral'
                  : 'obverse',
              ]},[_vm._v(" "+_vm._s(_vm.textData.tonalState == 2 ? "负面" : _vm.textData.tonalState == 0 ? "正面" : "中性")+" ")]),(_vm.textData.mediaLink != null)?_c('div',{staticClass:"type website"},[_vm._v(" "+_vm._s(_vm.textData.mediaLink)+" ")]):_vm._e(),_c('span',{staticClass:"original",on:{"click":function($event){return _vm.lookOriginal(_vm.textData.website)}}},[_vm._m(0),_c('span',[_vm._v("查看原文")])]),_c('div',{staticClass:"center-timer"},[_vm._v(" 发布时间: "),_c('span',[_vm._v(_vm._s(_vm.textData.releaseTime ? _vm.textData.releaseTime : "无"))])])]),_c('div',{staticClass:"center-bottom"},[_c('span',{staticClass:"center-meiti",staticStyle:{"margin-right":"40px"}},[_vm._v(" 作者ID: "),_c('span',[_vm._v(_vm._s(_vm.textData.accurateId ? _vm.textData.accurateId : "无"))]),_c('span',{staticStyle:{"width":"25px","height":"20px"}},[_c('span',{staticClass:"img my-caozuo",on:{"click":function($event){return _vm.myCopy(_vm.textData.accurateId, 5)}}},[_c('img',{attrs:{"src":require("../../../assets/img/views/hfz.png"),"alt":""}})])])]),_c('span',{staticClass:"center-meiti"},[_vm._v(" 媒体性质: "),_c('span',[_vm._v(_vm._s((_vm.textData.mediaLink ? _vm.textData.mediaLink : "其它") + "/" + (_vm.textData.mediaAttribute ? _vm.textData.mediaAttribute : "其它") + "/" + (_vm.textData.mediaLevel ? _vm.textData.mediaLevel : "其它")))]),_c('span',{staticStyle:{"width":"25px","height":"20px"}},[_c('span',{staticClass:"img my-caozuo",on:{"click":function($event){return _vm.myCopy(
                      [
                        _vm.textData.mediaLink,
                        _vm.textData.mediaAttribute,
                        _vm.textData.mediaLevel,
                      ],
                      1
                    )}}},[_c('img',{attrs:{"src":require("../../../assets/img/views/hfz.png"),"alt":""}})])])]),_c('span',{staticClass:"center-source"},[_vm._v(" 来源/作者: "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.textData.source ? _vm.textData.source : '无')}}),_c('span',[_vm._v("/")]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.textData.author ? _vm.textData.author : '无')}}),_c('span',{staticStyle:{"width":"25px","height":"20px"}},[_c('span',{staticClass:"img my-caozuo",on:{"click":function($event){return _vm.myCopy([_vm.textData.source, _vm.textData.author], 2)}}},[_c('img',{attrs:{"src":require("../../../assets/img/views/hfz.png"),"alt":""}})])])]),(_vm.textData.eventClass)?_c('span',{staticClass:"center-fenlei"},[_vm._v(" 事件分类: "),_c('span',[_vm._v(_vm._s(_vm.textData.eventList[0]))]),(_vm.textData.eventClass && _vm.textData.eventList.length >= 2)?_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top"}},[_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(_vm.textData.eventClass)},slot:"content"}),_c('span',{staticClass:"gengduo"},[_vm._v("(更多)")])]):_vm._e(),_c('span',{staticStyle:{"width":"25px","height":"20px"}},[_c('span',{staticClass:"img my-caozuo",on:{"click":function($event){return _vm.myCopy(_vm.textData.eventClass, 3)}}},[_c('img',{attrs:{"src":require("../../../assets/img/views/hfz.png"),"alt":""}})])])],1):_c('span',{staticClass:"center-fenlei"},[_vm._v(" 事件分类: 无 "),_c('span',{staticStyle:{"width":"25px","height":"20px"}},[_c('span',{staticClass:"img my-caozuo",on:{"click":function($event){return _vm.myCopy(_vm.textData.eventClass, 3)}}},[_c('img',{attrs:{"src":require("../../../assets/img/views/hfz.png"),"alt":""}})])])]),_c('div',{staticClass:"center-key-box"},[_c('div',{staticClass:"center-key"},[_vm._v(" 关键字: "),(_vm.textData.keywordList)?_c('div',_vm._l((_vm.textData.keywordList),function(ite,indx){return _c('span',{key:indx,class:[
                      ite !=
                      _vm.textData.keywordList[_vm.textData.keywordList.length - 1]
                        ? 'styleMargin'
                        : '',
                    ]},[_vm._v(_vm._s(ite))])}),0):_c('span',[_vm._v("无")])]),(
                  _vm.textData.keyword &&
                  _vm.textData.keywordArry &&
                  _vm.textData.keywordArry.length > 10
                )?_c('div',{staticClass:"gengduo-link-box"},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","placement":"top"}},[_c('div',{attrs:{"slot":"content"},domProps:{"innerHTML":_vm._s(_vm.textData.keyword)},slot:"content"}),_c('span',{staticClass:"gengduo-link"},[_vm._v("(更多)")])])],1):_vm._e(),_c('span',{staticStyle:{"width":"25px","height":"20px","margin-left":"5px","margin-top":"3px"}},[_c('span',{staticClass:"img my-caozuo",on:{"click":function($event){return _vm.myCopy(_vm.textData.keyword, 4)}}},[_c('img',{attrs:{"src":require("../../../assets/img/views/hfz.png"),"alt":""}})])])])])]),_c('div',{staticClass:"main-top-right"})]),_c('div',{staticStyle:{"padding":"32px 210px 0 210px","min-height":"430px"}},_vm._l((_vm.textData.contentList),function(item,index){return _c('p',{key:index,staticClass:"main-text",domProps:{"innerHTML":_vm._s(item ? item : '')}})}),0)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('img',{attrs:{"src":require("../../../assets/img/views/wenzhang.png"),"alt":""}})])
}]

export { render, staticRenderFns }